/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../css/bootstrap/bootstrap.min.css');
require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// var $ = require('jquery');
//
// require('bootstrap');
// require('../js/slick/slick.min');
// require('../js/home');

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);



$(document).ready(function() {
    if (!!$.cookie('verify')) {
        $('.verify').addClass('off');
    } else {
        $('.verify').removeClass('off');
    }
    $(".cb_age").change(function() {
        if(this.checked) {
            $('.btn-age').removeClass('disabled');
        } else {
            $('.btn-age').addClass('disabled');
        }
    });
    $('.btn-age').click(function() {
        if(!$(this).hasClass('disabled')) {
            validate_age();
        }
    });
    $( ".burger" ).click(function() {
        $('nav').css('display', 'block');
    });

    $( ".close-menu" ).click(function() {
        $('nav').css('display', 'none');
    });
});
function validate_age() {
    $.cookie('verify', 'ok', { expires: 7});
    $('.verify').addClass('off');
}
